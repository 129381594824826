<template lang="pug">
    div
        v-snackbar(
            :timeout="3000",
            :top="true"
            :right="true"
            :color="galleryColor"
            v-model="galleryMsj")
                | {{ galleryText }}
                v-btn(flat, dark, @click.native="galleryMsj = false") {{ $t('cerrar') }}
        v-dialog(v-if="galleryModal" v-model="galleryModal" max-width="600")
            v-form
                v-card
                    v-card-title {{ $t('Imagen') }}
                    v-card-text
                        v-layout(row wrap)
                            v-flex(xs12 v-if="isadmin")
                                v-select.mb-3(
                                    v-model="galleryModel.networkid"
                                    :items='networkNames'
                                    item-text="name"
                                    item-value="uuid"
                                    label='Red'
                                    append-icon="keyboard_arrow_down" 
                                    autocomplete
                                    :filter="vFilter"
                                )
                            v-flex(xs12)
                                v-text-field(:label="$t('Título')" v-model="galleryModel.title")
                            v-flex(xs12)
                                v-text-field(multi-line :label="$t('Descripción')" v-model="galleryModel.description")
                            v-flex(xs12)
                                h3.mb-3 {{ $t('Imagen') }}
                                v-btn.btn-file(color="success" outline block large) 
                                    v-icon(left) file_upload
                                    | {{ $t('Subir foto') }}
                                    input(type='file' @change='fileChange($event, "imageImg", "image")')
                                .img-file(v-if="galleryModel.imageImg.length")
                                    img(:src="galleryModel.imageImg")
                                    v-icon(@click='removeImage("imageImg", "image")') close
                                .img-file(v-else-if="galleryModel.image")
                                    img(:src="imgurl + galleryModel.image + '?time=' + galleryModel.time")
                    v-card-actions
                        .btns.btns-right
                            v-btn(color="secondary" small @click="galleryModal = false") {{ $t('Cancelar') }}
                            v-btn(:loading="loadingBtn" color="primary" large @click.stop="saveGallery") {{ $t('Guardar') }}
</template>

<script>

import fn from 'mixins/fn'
import config from 'src/config'

export default {
    mixins: [fn],
    data(){
        return {
            loadingBtn: false,
            gallerySchema: {
                uuid       : '',
                networkid  : '',
                imageImg   : '',
                image      : '',
                title      : '',
                description: ''
            },
            galleryModel: {},
            galleryMsj  : false,
            galleryColor: '',
            galleryText : '',
            galleryModal: false,
            networkNames : [],
        }
    },
    computed:{
        imgurl(){
            return config.uploadimg
        },
        galleryurl(){
            return config.galleryurl
        }
    },
    methods: {
        open(action, title, data = this.gallerySchema){

            this.fetchNetworkNames()
            this.galleryModel = Object.assign({}, this.gallerySchema)
            
            this.titleGallery  = title
            this.updateGallery = (action == 'add' ? false: true) 
            this.galleryModel  = Object.assign({}, this.galleryModel, data)
            
            this.galleryModel.description  = this.galleryModel.description == null ? ' ' : this.galleryModel.description
            this.galleryModel.image  = this.galleryModel.image == null ? '': this.galleryModel.image
            
            this.loadingBtn = false
            this.galleryModal  = true
        },
        fileChange(e, model, save) {

            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], model, save)
        },
        createImage(file, model, save) {

            let reader = new FileReader();
            reader.onload = (e) => this.galleryModel[model] = e.target.result
            reader.readAsDataURL(file)
            this.galleryModel[save] = file
        },
        removeImage(model, save) {
            this.galleryModel[model] = ''
            this.galleryModel[save] = ''
        },
        saveGallery(){

            if(this.galleryModel.uuid.length && !(this.profile.uuid == this.galleryModel.userid || this.isadmin || this.isgallery || this.hasAction('Galería', 'Editar'))){
                return
            }

            if(!this.galleryModel.uuid.length && !(this.profile.uuid == this.galleryModel.userid || this.isadmin || this.isgallery || this.hasAction('Galería', 'Crear'))){
                return
            }

            let post = Object.assign({}, this.galleryModel)
            let form = new FormData()

            for(let i in post){
                form.append(i, post[i])
            }

            this.loadingBtn = true
            this.$api(this, (xhr) => {
                xhr.post('/gallery', form).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.galleryMsj = true

                    if(data.response){
                        
                        this.galleryColor = 'success'
                        this.galleryText = data.message
                        this.galleryModal = false
                        this.$emit('update')

                    }else{
                        this.galleryColor = 'error'
                        this.galleryText = data.message
                    }
                }).catch(() => {})
            })
        },
        fetchNetworkNames(){

            let url = '/network?list=1'

            if(this.$root.profile.type == 2){
                url = '/network/profile?list=1'
            }

            this.$api(this, (xhr) => {
                xhr.get(url).then((r) => {

                    let data = r.data
                    if(data.response){
                        let networkNames = [[]].concat(data.data) 
                        this.networkNames = networkNames
                    }
                }).catch(() => {})
            })
        },
    }
}
</script>