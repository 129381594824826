<template lang="pug">
div
    v-dialog(v-model="modal" max-width="900" persistent)
        v-form(v-if="modal")
            v-card
                v-card-title
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="close")
                            v-icon close
                    | {{ $t('Casas de registro') }}
                v-card-text
                    p {{ $t('A continuación se muestra el listado de casas de registro cercanas a tu ubicación, de clic sobre el marcador en el mapa para ver la información') }}
                    v-layout(row wrap)
                        v-flex(xs12)
                            template(v-if="deniedLocation")
                                .not-found
                                    h3 {{ $t('Habilite la ubicación en su navegador para poder ver las casas de registro, luego recargue la página web') }}
                            template(v-else)
                                v-progress-circular(v-if="!showMap" indeterminate color="primary") 
                                l-map(v-if="showMap" :options="{attributionControl:false}" :center="center" :zoom="zoom" ref="map" @moveend="updateMap" style="min-height:380px;")
                                    l-control(position="bottomright" v-if="loadStand")
                                        v-progress-circular(indeterminate color="primary") 
                                    template(v-for="(stand, i) in stands")
                                        l-marker(
                                            :lat-lng="[stand.lat, stand.lng]"
                                            :icon="currentStand.uuid == stand.uuid ? activeIcon : icon"
                                            @click="currentStand=stand,standModal=true"
                                        )
                                            l-tooltip
                                                span.d-block {{ $t('Casa de registro') }} - {{ stand.title }}
                                                span.d-block {{ stand.address }}
                        template(v-if="standList.length")
                            v-flex(xs12)
                                h3.my-3 {{ $t('Listado de casas de registro') }}
                            v-flex(xs4)
                                template(v-if="loadStandList")
                                    v-progress-circular(indeterminate color="primary") 
                                template(v-else)
                                    v-list.stand-list(light)
                                        v-list-tile(@click="currentStand=stand,setStandOnMap(stand)" v-for="(stand, i) in standList" :key="'stand'+i" :class="{'active': Object.keys(currentStand).length && currentStand.uuid == stand.uuid}")
                                            v-list-tile-content
                                                v-list-tile-title {{ stand.title }}
                                                v-list-tile-sub-title {{ stand.address }}
                                    infinite-loading(:infinite="infinite" ref="infinite")
                        v-flex(xs8)
                            .not-found(v-if="!Object.keys(currentStand).length")
                                h3 {{ $t('Seleccione una casa de registro') }}
                            template(v-else)
                                h3 {{ currentStand.title }}
                                p {{ currentStand.description }}
                                v-divider.x1.primary.my-2
                                span.d-block {{ $t('Dirección') }}: {{ currentStand.address }}
                                span.d-block {{ $t('Teléfono') }}: {{ currentStand.author_phone }}
                                span.d-block {{ $t('Responsable') }}: {{ currentStand.author_names }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="close") {{ $t('Cerrar') }}
    v-dialog(v-model="standModal" max-width="600" persistent)
        v-form(v-if="standModal")
            v-card
                v-card-title
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="standModal=false")
                            v-icon close
                    | {{ $t('Casa de registro') }} - {{ currentStand.title }}
                v-card-text
                    v-layout(row wrap)
                        v-flex(xs12)
                            p {{ currentStand.description }}
                            v-divider.x1.primary.my-2
                            span.d-block {{ $t('Dirección') }}: {{ currentStand.address }}
                            span.d-block {{ $t('Teléfono') }}: {{ currentStand.author_phone }}
                            span.d-block {{ $t('Responsable') }}: {{ currentStand.author_names }}
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="standModal=false") {{ $t('Cancelar') }}
</template>

<script>

import _d from 'lodash/debounce'
import config from 'src/config'

export default {
    data() {
        return {
            preload: false,
            modal: false,
            standModal: false,
            currentNetwork: '',
            stands : [],

            // Mapa
            center      : null,
            zoom        : 11,
            showMap     : false,
            bb          : {},
            l           : null,
            currentStand: {},
            loadStand   : false,
            latLng: [],
            deniedLocation: false,

            standList: [],
            loadStandList: false,
            limit : this.$root.limit,
            offset: 0
        }
    },
    computed: {
        icon() {
            return this.l.icon({
                iconUrl: config.url + 'public/img/icons/home-inactive.png',
                iconSize: [25,25]
            })
        },
        activeIcon() {
            return this.l.icon({
                iconUrl: config.url + 'public/img/icons/home-active.png',
                iconSize: [25,25]
            })
        }
    },
    mounted(){
        this.l = require('leaflet')
    },
    methods: {
        async open(currentNetwork) {
            this.deniedLocation = false
            this.currentNetwork = currentNetwork
            this.currentStand = {}
            this.modal = true
            
            this.latLng  = await this.getLatLng()
            this.center  = this.l.latLng(this.latLng)
            this.showMap = true

            setTimeout(() => {

                this.l.gridLayer.googleMutant({
                    type: 'roadmap'
                }).addTo(this.$refs.map.mapObject)

                this.addGeocoder()

                let bounds = this.$refs.map.mapObject.getBounds()
                let bb = {
                    bl: bounds.getSouthWest(),
                    tr: bounds.getNorthEast()
                }

                this.bb = bb
                this.fetchStand()
            
            }, 1)

            this.fetchStandList()
        },
        addGeocoder(){

            this.l.Control.geocoder({
                defaultMarkGeocode: false,
                placeholder       : this.$t('Buscar'),
                errorMessage      : this.$t('No se encontraron resultados'),
                geocoder          : new this.l.Control.Geocoder.Google({
                    geocodingQueryParams: {
                        key: config.mapsKey
                    }
                })
            }).on('markgeocode', (e) => {

                const latLng = e.geocode.center
                this.center  = this.l.latLng(latLng)

            }).addTo(this.$refs.map.mapObject)
        },
        updateMap: _d(function({target}){

            let zoom   = target.getZoom()
            let bounds = target.getBounds()

            let bb = {
                bl: bounds.getSouthWest(),
                tr: bounds.getNorthEast()
            }

            this.bb = bb
            this.zoom = zoom
            
            this.fetchStand()

        }, 500),
        fetchStand() {

            this.loadStand = true
            this.stands = []

            let filter = {
                networkid: this.currentNetwork.uuid,
                bb: JSON.stringify(this.bb)
            }

            this.$api(this, (xhr) => {
                xhr.get('/stand/list', { params: filter }).then((r) => {

                    let data = r.data
                    this.loadStand = false
                    this.stands = (data.response ? data.data : [])

                }).catch(() => {})
            })
        },
        async getLatLng(){

            return new Promise(async (resolve) => {

                let latLng = [4.6533326, -74.083652]
                if('geolocation' in navigator){

                    latLng = await new Promise(resolve => {
                        navigator.geolocation.getCurrentPosition(async (pos) => {

                            let coords = pos.coords
                            resolve([coords.latitude, coords.longitude])

                        }, async (error) => {
                            if (error.PERMISSION_DENIED) {
                                latLng = await this.getLocation()
                                resolve(latLng)
                            } else {
                                resolve(latLng)
                            }
                        })
                    })
                }
                resolve(latLng)
            })
        },
        close() {
            this.modal = false
            this.showMap = false
        },
        getLocation() {
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/location').then((r) => {
                        let data = r.data
                        resolve(data.data)
                    }).catch(() => {
                        resolve(false)
                    })
                })
            })
        },
        fetchStandList(infinite = false) {

            if(infinite){
                this.offset += this.limit
            }else{
                this.loadStandList = true
                this.standList = []
                this.offset = 0
                this.$refs.infinite && this.$refs.infinite.reset()
            }

            let filter = {
                limit : this.limit,
                offset: this.offset,
                networkid: this.currentNetwork.uuid
            }

            this.$api(this, (xhr) => {
                xhr.get('/stand/list-infinite', { params: filter }).then((r) => {

                    if(!infinite) this.loadStandList = false
                    let data = r.data

                    if(infinite){
                        if(data.response){
                            this.standList = this.standList.concat(data.data)
                            this.$refs.infinite && this.$refs.infinite.loaded()
                        }else{
                            this.$refs.infinite && this.$refs.infinite.complete()
                        }
                    }else{
                        this.standList = (data.response ? data.data : [])
                    }
                }).catch(() => {})
            })
        },
        infinite(){
            setTimeout(() => this.fetchStandList(true) , 500)
        },
        setStandOnMap(stand) {
            this.center = this.l.latLng([stand.lat, stand.lng])
        }
    }
}
</script>